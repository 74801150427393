<template>
  <div :style="{ backgroundImage: 'url(img/flower_rank_bg.png)' }"
    style="background-size:100% auto; min-height:100vh; background-color:#171726;background-repeat: no-repeat;">
    <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
      <div class="top">
        <div class="topback" @click="goBack"></div>
        <div class="hua_rank">排行榜</div>
        <div class="tabs">
          <div class="rank_tab"
            :style="clickIndex == 0 ? 'background-image:url(img/tab_click.png)' : 'background-image:url(img/tab_unclick.png)'"
            @click="clickRank">等级榜</div>
          <div class="flower_tab"
            :style="clickIndex == 1 ? 'background-image:url(img/tab_click.png)' : 'background-image:url(img/tab_unclick.png)'"
            @click="clickFlower">花瓣榜</div>
        </div>
        <div class="box-container-task" style="margin-top:30px;">
          <div v-if="nodata" class="noData">
          <img src="img/noData.png" alt="">
        </div>
          <div style="background-image: url('img/no2_bg.png');"
            class="box-top_two display_flex flex-direction_column align-items_center" v-if="topThreeItems.length >= 2">
            <div class="box-head2">
              <img class="box-img" :src="topThreeItems[1].head_portrait" alt="">
              <img class="box-bgimg2" src="img/no2_crown.png" alt="">
            </div>
            <div class="box-news display_flex justify-content_flex-justify align-items_center">
              <div class="box-name">{{ topThreeItems[1].nickname }}</div>
            </div>
            <div v-if="type==2" class="flower_rank_text">
                <div>距上一名</div>
                <div style="display:flex;align-items: center;justify-content: center;">
                  <img class="flower_icon" src="img/hua_icon.png" alt="">
                  <span>{{topThreeItems[1].diff_flower}}</span>
                </div>
              </div>
            <img v-if="type==1" class="level_img" :src="'img/level/flowerRank/' + topThreeItems[1].rank + '.png'" alt="">
          </div>
          <div style="background-image: url('img/no1_bg.png');"
            class="box-top_one display_flex flex-direction_column align-items_center" v-if="topThreeItems.length >= 1">
            <div class="box-head1">
              <img class="box-img" :src="topThreeItems[0].head_portrait" alt="">
              <img class="box-bgimg1" src="img/no1_crown.png" alt="">
            </div>
            <div class="box-news display_flex justify-content_flex-justify align-items_center">
              <div class="box-name">{{ topThreeItems[0].nickname }}</div>
            </div>
            <img v-if="type==1" class="level_img" :src="'img/level/flowerRank/' + topThreeItems[0].rank + '.png'" alt="">
          </div>
          <div style="background-image: url('img/no3_bg.png');"
            class="box-top_three display_flex flex-direction_column align-items_center" v-if="topThreeItems.length >= 3">
            <div class="box-head2">
              <img class="box-img" :src="topThreeItems[2].head_portrait" alt="">
              <img class="box-bgimg2" src="img/no3_crown.png" alt="">
            </div>
            <div class="box-news display_flex justify-content_flex-justify align-items_center">
              <div class="box-name">{{ topThreeItems[2].nickname }}</div>
            </div>
            <img v-if="type==1" class="level_img" :src="'img/level/flowerRank/' + topThreeItems[2].rank + '.png'" alt="">
            <div v-if="type==2" class="flower_rank_text">
                <div>距上一名</div>
                <div style="display:flex;align-items: center;justify-content: center;">
                  <img class="flower_icon" src="img/hua_icon.png" alt="">
                  <span>{{topThreeItems[2].diff_flower}}</span>
                </div>
              </div>
          </div>
        </div>
        <!-- <div class="myRank">暂未上榜</div> -->
      </div>
      <div class="list-scroll" v-if="listItems.length > 0">
        <template>
          <div class="item display_flex justify-content_flex-justify align-items_center"
            v-for="(item, index) in listItems" :key="index">
            <div class="display_flex align-items_center">
              <div class="item-order">{{ index + 4 }}</div>
              <img class="item-img" :src="item.head_portrait" alt="">
              <div class="item-name">{{ item.nickname }}</div>
            </div>
            <div>
              <img v-if="type==1" class="level_img" :src="'img/level/flowerRank/'+item.rank+'.png'" alt="">
              <div v-if="type==2" class="flower_rank_text">
                <div>距上一名</div>
                <div style="display:flex;align-items: center;justify-content: center;">
                  <img class="flower_icon" src="img/hua_icon.png" alt="">
                  <span>{{item.diff_flower}}</span>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </van-pull-refresh>
    <div class="fix-bottom" v-if="dis_flower>0">
      <div class="my_rank" v-if="type==1">我的排名：未上榜</div>
       <div class="my_rank_other" v-if="type==2">
          <span>距上榜</span>
          <div style="display: flex;align-items: center;">
            <img class="flower_icon" src="img/hua_icon.png" alt="">
            <span>{{dis_flower}}</span>
          </div>
       </div>
    </div>

  </div>
</template>

<script>
import "@/assets/css/flowerRank.css"
import { welFareRank } from "@/api/ranking";
export default {
  name: 'taskRank',
  data() {
    return {
      type: 1,
      clickIndex: 0,
      dis_flower:0,
      page: 1,
      topThreeItems: [],
      listItems: [],
      isLoading: false,
      nodata: false,
    }
  },
  created() {
    this.getData()
  },
  methods: {
    onRefresh() {
      setTimeout(() => {
        this.$toast('刷新成功')
        this.isLoading = false;
        this.getData()
      }, 1000);
    },
    clickRank() {
      this.clickIndex = 0
      this.type = 1
      this.page = 1
      this.getData()
    },
    clickFlower() {
      this.clickIndex = 1
      this.type = 2
      this.page = 1
      this.getData()
    },
    getData() {
      let params = {
        type: this.type
      }
      welFareRank(params).then(response => {
        const topThreeItems = response.data.data.slice(0, 3);
        const listItems = response.data.data.slice(3);
        this.topThreeItems = topThreeItems
        this.listItems = listItems
        this.dis_flower = response.data.dis_flower
        if (response.data.data.length == 0) {
          this.nodata = true
        } else {
          this.nodata = false
        }
      },
        error => {
          this.$toast.fail(error)
        }).catch((error) => {
          this.$toast.fail(error)
        })
    },
    goBack() {
      this.$router.go(-1)
    }
  },
}
</script>